<template>
    <div id="app">
        <router-view :key="key"/>
    </div>
</template>

<script>

export default {
  name: 'app',
    computed:{
      key(){
          return this.$route.name + this.$route.fullPath+Math.random();
      }
    },
    /*provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
        return {
            reload: this.reload
        }
    },
    data() {
        return{
            isRouterAlive: true                    //控制视图是否显示的变量
        }
    },*/
    methods:{
      //控制router-view的显示或隐藏，从而控制页面的再次加载
      /*reload(){
          this.isRouterAlive = false
          this.$nextTick(function (){
              this.isRouterAlive = true
          })
      }*/
    }
}
</script>

