import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)


export default new Router({
    routes: [
        { path: '/',name:'Home',component: () => import('../components/Home'), meta: {title: '实时'}},
        { path: '/Week', name:'Week',component: () => import('../components/Week'), meta: {title: '周报'} },
        { path: '/Loginfo', name:'Loginfo', component: () => import('../components/Loginfo'), meta: {title: '日志'} },
        { path: '/Myoldman', name:'Myoldman', component: () => import('../components/Myoldman'), meta: {title: '我的'} },
        { path: '/Daylife', name:'Daylife',component: () => import('../components/Daylife'), meta: {title: '作息表'} },
        { path: '/Reg', name:'Reg',component: () => import('../components/Reg'), meta: {title: '注册'} },
        { path: '/Wristband', name:'Wristband', component: () => import('../components/Wristband'), meta: {title: '手环信息'} },
        { path: '/Bloodhistory', name:'Bloodhistory', component: () => import('../components/Bloodhistory'), meta: {title: '血压记录'} },
        { path: '/Hearthistory', name:'Hearthistory', component: () => import('../components/Hearthistory'), meta: {title: '心率记录'} },
        { path: '/Mansetting', name:'Mansetting', component: () => import('../components/Mansetting'), meta: {title: '老人信息'} },
        { path: '/Setting', name:'Setting', component: () => import('../components/Setting'), meta: {title: '设置'} },
        /*{ path: '/Renew', name:'Renew', component: () => import('../components/Renew'), meta: {title: '服务有效期'} },*/
        { path: '/Agreement', name:'Agreement', component: () => import('../components/Agreement'), meta: {title: '用户协议与隐私条款'} },
        { path: '/Servicetime', name:'Servicetime', component: () => import('../components/Servicetime'), meta: {title: '服务有效期'} },
        { path: '/Device', name:'Device', component: () => import('../components/Device'), meta: {title: '我的设备'} },
        { path: '/Introduce', name:'Introduce', component: () => import('../components/Introduce'), meta: {title: '居家养老智能守护平台'} },
    ]
})