import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './utils/date'
//import { WechatPlugin } from 'vux'
//Vue.use(WechatPlugin)
console.log(Vue.wechat) // 可以直接访问 wx 对象。
//import wx from 'weixin-js-sdk'
//Vue.prototype.$wx = wx
//import wx from 'weixin-jsapi'
//Vue.prototype.wx = wx;
import DatePicker from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';
import Toast from '@nutui/nutui';

import axios from 'axios'
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = '' // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL



DatePicker.install(Vue);
Vue.config.productionTip = false
Toast.install = function () {
    window.ts = Toast;
}
Toast.install();


new Vue({
    router,
    el: '#app',
    render: h => h(App),
}).$mount('#app')
const img = {
  'Home': ['img/b_1.png', 'img/b_1_1.png'],
  'Week': ['img/b_2.png', 'img/b_2_1.png'],
  'Loginfo': ['img/b_3.png', 'img/b_3_1.png'],
  'Myoldman': ['img/b_4.png', 'img/b_4_1.png'],
  'Daylife': ['img/b_1.png', 'img/b_1_1.png']
};

router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (document.getElementById(from.name)) {
        document.getElementById(from.name).className = ''
        document.getElementById(from.name).getElementsByTagName('img')[0].src = img[from.name][1]
    }
  if (document.getElementById(to.name)) {
    document.getElementById(to.name).className = 'dq'
    document.getElementById(to.name).getElementsByTagName('img')[0].src = img[to.name][0]
  }
})


